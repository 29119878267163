import React, { createContext, useEffect, useReducer } from "react";
import axios from "./axios";
import {
  defaultCreatedAt,
  defaultSheetId,
  getCookie,
  getDomain,
} from "./utils";
import { useHistory } from "react-router-dom";

export const AppContext = createContext();

const loadingGameInfo = {
  name: "Loading...",
  googleSheetId: defaultSheetId,
  googleModified: defaultCreatedAt,
  locallyModified: defaultCreatedAt,
};

const defaultGameInfo = {
  name: "Test Vocabulary",
  googleSheetId: defaultSheetId,
  googleModified: defaultCreatedAt,
  locallyModified: defaultCreatedAt,
};

const defaultVocabulariesLoading = {
  isCommonVocabulary: false,
  games: [loadingGameInfo, loadingGameInfo, loadingGameInfo],
};

const defaultVocabulariesLoaded = {
  isCommonVocabulary: false,
  games: [defaultGameInfo, defaultGameInfo, defaultGameInfo],
};

const initState = {
  popup: null,
  user: null,
  isAdsEnabled: false,
  postHubFilter: "new",
  domain: getDomain(),
  domainStatic: getDomain(true),
  isDrawerOpen: false,
  isMobile: false,
  siteName: "Dictionarying",
  popupSubscribeToPremium: false,
  appName: "site",
  isMusicMuted: true,
  isSoundsMuted: false,
  goFullscreen: false,
  vocabularies: defaultVocabulariesLoading,
};

const reducer = (state, payload) => {
  const newState = {
    ...state,
    ...payload,
  };
  console.log("payload >", payload);
  console.log("new state >", newState);
  if (typeof window !== "undefined") {
    try {
      window.localStorage.setItem("_VING_APP_", JSON.stringify(newState));
    } catch (e) {
      //
    }
  }
  return newState;
};

export const AppProvider = ({ children }) => {
  let locallySavedState = localStorage.getItem("_VING_APP_");
  if (locallySavedState) {
    console.log("locallySavedState=========>", locallySavedState);
    locallySavedState = JSON.parse(locallySavedState);
    locallySavedState = { ...locallySavedState, popup: null };
  }
  const defaultState = locallySavedState ? locallySavedState : initState;
  const [appState, appDispatch] = useReducer(reducer, defaultState);
  const history = useHistory();

  const getUserProfileInfo = async () => {
    try {
      const res = await axios.get("/api/auth/user-info");
      const user = res.data;
      appDispatch({
        user,
        siteName: "Dictionarying",
      });
    } catch (error) {
      localStorage.removeItem("_IELTS69_JWT_");
      localStorage.removeItem("_VING_APP_");
      //history.push("/login");
    }
  };

  useEffect(() => {
    async function fetchData() {
      await getUserProfileInfo();
    }
    fetchData();

    if (window.screen.width < 800) {
      appDispatch({
        isMobile: true,
      });
    } else {
      appDispatch({
        isMobile: false,
      });
    }
  }, []);

  return (
    <AppContext.Provider value={[appState, appDispatch]}>
      {children}
    </AppContext.Provider>
  );
};
