import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { getJWT, removeJWT } from "../utils";
import { useContext, useEffect, useState } from "react";
import axios from "../axios";
import { CSVLink } from "react-csv";
import { AppContext } from "../context";

const Account = () => {
  const history = useHistory();
  const selected = "hover:underline text-yellow-300";
  const normal = "hover:underline hover:text-yellow-300";
  const [data, setData] = useState();
  const [appState, appDispatch] = useContext(AppContext);

  const location = useLocation();

  useEffect(() => {
    const loadData = async () => {
      const data = await axios.get("/api/tests/saved-words");
      console.log("xxxxxxxxxx+>", data);
      setData(data.data);
    };
    loadData();
  }, [location.pathname]);

  const page = location.pathname;

  function getDomain(url) {
    try {
      // Create a URL object
      const urlObj = new URL(url);
      // Get the hostname (e.g., www.example.com)
      let domain = urlObj.hostname;
      // Remove the 'www.' prefix if it exists
      if (domain.startsWith("www.")) {
        domain = domain.slice(4);
      }
      // Remove the '.com' suffix if it exists
      if (domain.endsWith(".com")) {
        domain = domain.slice(0, -4);
      }
      return domain;
    } catch (error) {
      console.error("Invalid URL:", error);
      return null; // Return null if the URL is invalid
    }
  }

  const isPremium = ["FULL2", "DICTIONARY", "DICVOC"].includes(
    appState?.user?.role
  );

  return (
    <div className="">
      <section className="bg-customBlack">
        <div className="relative h-screen max-w-[1200px] mx-auto">
          <div className="relative flex flex-row justify-between px-8 mt-6 text-white z-2">
            <a href="/">
              <img
                src="/static/logo/dictionarying.svg"
                alt="dictionarying"
                width={150}
                className="cursor-pointer"
              />
            </a>

            <div className="flex flex-row items-center justify-center gap-8 text-white">
              <a
                href="/saved-words"
                className={page === "/saved-words" ? selected : normal}
              >
                Saved Words
              </a>
              <a
                href="/account"
                className={page === "/account" ? selected : normal}
              >
                Account
              </a>
              {getJWT() && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    removeJWT();
                    window.dispatchEvent(
                      new CustomEvent("LOGOUT", { detail: {} })
                    );
                    window.location.reload();
                  }}
                  href="#log-out"
                  className="hover:underline hover:text-yellow-300"
                >
                  Log Out
                </a>
              )}
            </div>
          </div>
          <div className="px-8 pb-8 text-center">
            <h1 className="mx-auto text-3xl text-center text-white m-7 center">
              {location.pathname === "/saved-words" && <>Saved Words</>}
              {location.pathname === "/account" && <>User Account</>}
            </h1>
            <div className="relative inline-block mx-auto my-10 text-white border border-black border-solid rounded-md boxShadow">
              <style>{`
            table {
          border-spacing: 1px;
          border-collapse: separate;
        }
        table thead td {
          padding: 5px;
          background-color: #000 !important;
          color: #fff;
          font-weight: bold;
        }
        table td,
        table th {
          padding: 8px;
        }

        table tr:nth-child(odd) td 
        {
          background-color: #000;
      }
         
      table tr:nth-child(even) td,
        table tr th{
        background-color: #222;
      }



             `}</style>

              {location.pathname === "/saved-words" && (
                <>
                  {data?.length > 0 && (
                    <CSVLink
                      data={data.map((item) => {
                        const { original, translation } = item;
                        return { original, translation };
                      })}
                      className="absolute right-0 -mt-10"
                    >
                      Download CSV
                    </CSVLink>
                  )}

                  <table width={"800"}>
                    <thead>
                      <tr>
                        <th>Word</th>
                        <th>Translation</th>
                        <th>Source</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    {data?.length > 0 ? (
                      <tbody>
                        {data.map((item) => {
                          return (
                            <tr>
                              <td>{item.original}</td>
                              <td>{item.translation}</td>
                              <td align="center">
                                <a href={item.sourceURL} className="capitalize">
                                  {getDomain(item.sourceURL)}
                                </a>
                              </td>
                              <td align="center">
                                {String(
                                  new Date(item.createdAt).toLocaleString(
                                    undefined,
                                    {
                                      timeZone:
                                        Intl.DateTimeFormat().resolvedOptions()
                                          .timeZone,
                                    }
                                  )
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={4} align="center">
                            <br />
                            <br />
                            No Saved Words
                            <br />
                            <br />
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </>
              )}

              {location.pathname === "/account" && appState.user && (
                <table width={"100%"} className="account">
                  <style>{`
table.account {
  width: 400px !important;
}
table.account th,
table.account td {
  width: 50%;
}
  table.account tbody td:first-child {
  text-align: left;
  }
  table.account tbody td:nth-child(2) {
  text-align: center;
  }

table.account tr:nth-child(even) td,
table.account tr th{
  background-color: #000;
}
                  `}</style>
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td align="center">{appState.user.name}</td>
                    </tr>

                    {isPremium ? (
                      <>
                        <tr>
                          <td>Subscription</td>
                          <td align="center">Premium</td>
                        </tr>
                        <tr>
                          <td>Premium Expiration</td>
                          <td align="center">
                            {String(
                              new Date(
                                appState.user.premiumExpirationDate
                              ).toLocaleString(undefined, {
                                timeZone:
                                  Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                              })
                            )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          <td>Subscription</td>
                          <td align="center">Basic</td>
                        </tr>
                        <tr>
                          <td colSpan={2} align="center">
                            <center>
                              <a href="/pricing" className="underline">
                                Go Premium
                              </a>
                            </center>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Account;
