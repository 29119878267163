import { useContext, useState, useEffect } from "react";

const Features = () => {
  const [currentFeatureTab, setCurrentFeatureTab] = useState(1);
  return (
    <section
      id="features"
      className="relative pt-[150px] md:pt-0 md:h-screen bg-customBlack md:snap-start"
    >
      <div className="relative md:h-screen max-w-[800px] flex items-center mx-auto">
        <div className="flex flex-col">
          <div class="tabs-container boxShadow">
            <div class="tabs-block">
              <div id="tabs-section" class="tabs">
                <ul class="tab-head">
                  <li>
                    <a
                      class="tab-link"
                      href="#tab-2"
                      className={
                        currentFeatureTab === 1 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(1);
                      }}
                    >
                      <svg
                        fill="currentColor"
                        width="23px"
                        height="23px"
                        className="inline-block mx-auto"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2M4 12h4v2H4zm10 6H4v-2h10zm6 0h-4v-2h4zm0-4H10v-2h10z"></path>
                      </svg>
                      <span class="tab-label">Customize Subtitles</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-1"
                      className={
                        currentFeatureTab === 2 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(2);
                      }}
                    >
                      <svg
                        fill="currentColor"
                        width="20px"
                        height="20px"
                        viewBox="0 0 52 52"
                        className="inline-block mx-auto"
                      >
                        <path d="M39,18.67H35.42l-4.2,11.12A29,29,0,0,1,20.6,24.91a28.76,28.76,0,0,0,7.11-14.49h5.21a2,2,0,0,0,0-4H19.67V2a2,2,0,1,0-4,0V6.42H2.41a2,2,0,0,0,0,4H7.63a28.73,28.73,0,0,0,7.1,14.49A29.51,29.51,0,0,1,3.27,30a2,2,0,0,0,.43,4,1.61,1.61,0,0,0,.44-.05,32.56,32.56,0,0,0,13.53-6.25,32,32,0,0,0,12.13,5.9L22.83,52H28l2.7-7.76H43.64L46.37,52h5.22Zm-15.3-8.25a23.76,23.76,0,0,1-6,11.86,23.71,23.71,0,0,1-6-11.86Zm8.68,29.15,4.83-13.83L42,39.57Z" />
                      </svg>{" "}
                      <span class="tab-label">Translate Words</span>
                    </a>
                  </li>

                  <li>
                    <a
                      class="tab-link"
                      href="#tab-3"
                      className={
                        currentFeatureTab === 3 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(3);
                      }}
                    >
                      <svg
                        height="18px"
                        width="18px"
                        viewBox="0 0 27.963 27.963"
                        className="inline-block mx-auto"
                      >
                        <path
                          fill="currentColor"
                          d="M13.98,0C6.259,0,0,6.26,0,13.982s6.259,13.981,13.98,13.981c7.725,0,13.983-6.26,13.983-13.981
C27.963,6.26,21.705,0,13.98,0z M21.102,16.059h-4.939v5.042h-4.299v-5.042H6.862V11.76h5.001v-4.9h4.299v4.9h4.939v4.299H21.102z"
                        />
                      </svg>
                      <span class="tab-label">Save Words</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-4"
                      class={
                        currentFeatureTab === 4 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(4);
                      }}
                    >
                      <svg
                        fill="currentColor"
                        height="25px"
                        width="25px"
                        className="inline-block mx-auto"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                      >
                        <path d="M17.41 6.59 15 5.5l2.41-1.09L18.5 2l1.09 2.41L22 5.5l-2.41 1.09L18.5 9zm3.87 6.13L20.5 11l-.78 1.72-1.72.78 1.72.78.78 1.72.78-1.72L23 13.5zm-5.04 1.65 1.94 1.47-2.5 4.33-2.24-.94c-.2.13-.42.26-.64.37l-.3 2.4h-5l-.3-2.41c-.22-.11-.43-.23-.64-.37l-2.24.94-2.5-4.33 1.94-1.47c-.01-.11-.01-.24-.01-.36s0-.25.01-.37l-1.94-1.47 2.5-4.33 2.24.94c.2-.13.42-.26.64-.37L7.5 6h5l.3 2.41c.22.11.43.23.64.37l2.24-.94 2.5 4.33-1.94 1.47c.01.12.01.24.01.37s0 .24-.01.36M13 14c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3"></path>
                      </svg>
                      <span class="tab-label">Other Features</span>
                    </a>
                  </li>
                  {/* <li>
                <a href="#tab-5" class="tab-link">
                  {" "}
                  <span class="material-icons tab-icon">toll</span>{" "}
                  <span class="tab-label">Blush</span>
                </a>
              </li> */}
                </ul>

                <section
                  id="tab-2"
                  className={
                    currentFeatureTab === 1
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">
                    Customize Subtitles
                  </h2>
                  <p>
                    You can drag subtitles on the screen and place them in the
                    best possible location.
                  </p>
                  <p>
                    You can customize subtitles by changing the font size and
                    the background of the subtitles.
                  </p>
                  <p>
                    If a video lacks subtitles, you can add your own, and
                    they’ll display as native to the platform.
                  </p>
                </section>

                <section
                  id="tab-1"
                  className={
                    currentFeatureTab === 2
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">Translate Words</h2>
                  <p>
                    Choose your native language, and any subtitles will be
                    translated into it.
                  </p>
                  <p> You can translate a word by clicking on it.</p>
                  <p>
                    You can also translate an entire sentence by clicking on the
                    black background of the subtitles.
                  </p>
                </section>

                <section
                  id="tab-3"
                  className={
                    currentFeatureTab === 3
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">Save Words</h2>
                  <p>You can save the word that you just translated.</p>
                  <p>
                    This word will be added to the database, and later you can
                    use it to learn and review.
                  </p>
                  <p>
                    You can also export it to Excel and use other word learning
                    services, such as{" "}
                    <a
                      href="https://vocabularying.com"
                      className="text-yellow-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      vocabularying.com
                    </a>
                    .
                  </p>
                </section>

                <section
                  id="tab-4"
                  className={
                    currentFeatureTab === 4
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">Other Features</h2>
                  <p>You can translate a word by clicking on the plus icon.</p>
                  <p>
                    Right now our extension supports subtitle translation on
                    Netflix, Youtube Coursera and Amazon Video Prime.
                  </p>
                  <p>
                    We are constantly working on improving user expeirience to
                    bring the best service for you.
                  </p>
                </section>

                <section
                  id="tab-5"
                  className={
                    currentFeatureTab === 5
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2>Blush</h2>
                  <p>
                    Putting on blush can have a huge effect on your overall
                    look, and I personally never leave it out of my makeup
                    routine. Blush is especially necessary if you’re wearing a
                    foundation with more opaque coverage, which can sometimes
                    leave your complexion looking a little bit flat.
                  </p>
                  <p>
                    Blush comes in powder, gel, and cream formulations, with
                    powder being the most popular. Recently, though, cream and
                    gel blush have become very popular as well.
                  </p>
                </section>
              </div>
            </div>
          </div>
          <div className="relative flex flex-row justify-between px-2 transition duration-500 mt-14 opacity-40 hover:opacity-100">
            <div className="absolute text-white -translate-x-1/2 -top-6 left-1/2">
              Supported Platforms
            </div>
            <div class="w-[17%] px-[3%] flex justify-center">
              <img src="/static/logo/prime.svg" class="w-full object-contain" />
            </div>

            <div class="w-[17%] flex justify-center">
              <img
                src="/static/logo/youtube.svg"
                class="w-full object-contain"
              />
            </div>

            <div class="w-[17%] flex justify-center">
              <img
                src="/static/logo/netflix.svg"
                class="w-full object-contain"
              />
            </div>
            <div class="w-[17%] flex justify-center">
              <img
                src="/static/logo/coursera.svg"
                class="w-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
