import React, { useRef, useState, useEffect } from "react";

export const SlidingTabBar = ({
  activeTabIndex,
  setActiveTabIndex,
  allTabs,
}) => {
  const tabsRef = useRef([]);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  useEffect(() => {
    if (activeTabIndex === null || !allTabs[activeTabIndex]) return;

    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();

    return () => {
      console.log("SlidingTabBar unmounted or activeTabIndex changed");
    };
  }, [activeTabIndex, allTabs]);

  return (
    <div className="tab-bar-container">
      <style>{`
 /* Container styling for the tab bar */
.tab-bar-container {
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 30px auto 35px;
  height: 48px;
  border-radius: 10px; 
  background-color: #000; /*#fff;*/
  padding: 2px;
  backdrop-filter: blur(8px);
  box-shadow: 0 0 0 1px rgb(255 255 255 / 5%), 0 1px 8px rgb(255 255 255 / 15%);
  max-width: 298px;
}
  .redBg{
   background-color: #f50057 !important;
  }

/* Underline styling */
.tab-underline {
  position: absolute;
  bottom: 0;
  top: 0;
  z-index: 0;
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  padding: 2px 0px;
  transition: all 0.3s;
}

// span.tabName{
//   font-weight: bold;
// }

.tab-underline-inner {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background-color: #fde259;
}

/* Button styling */
.tab-button {
  margin: auto 0;
  cursor: pointer;
  user-select: none;
  border-radius: 16px; /* Adjust as needed */
  padding: 0 16px; /* 4 * 4px */
  text-align: center;
  font-weight: 300;
  font-size: 18px;
  color: #fff;
  transition: color 0.3s;
  flex: 1;
      display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    z-index:5;
        font-family: "Arial", "Helvetica", "Roboto", sans-serif;
}

.tab-button:hover {
  color: #fde259; /* Neutral-300 equivalent */
}

.tab-button.active {
color: #000;
}

.discountBadge {
  background-color: #09b78e;
  color: #fff;
  padding: 2px 7px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px;
  display: inline-block;
  margin-left: 6px;
  margin-right: -10px;

}

@media (max-width: 700px) {
.tab-button {
    padding: 0 5px !important;
}
    .discountBadge{
    display: none !important;
    }
}


`}</style>
      <span
        className="tab-underline"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      >
        <span className="tab-underline-inner" />
      </span>
      {allTabs.map((tab, index) => {
        const isActive = activeTabIndex === index;

        return (
          <div
            key={index}
            ref={(el) => (tabsRef.current[index] = el)}
            className={`tab-button ${isActive ? "active" : ""}`}
            onClick={() => setActiveTabIndex(index)}
          >
            <span className="tabName">{tab.name}</span>
          </div>
        );
      })}
    </div>
  );
};
